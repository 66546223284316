import { action } from 'typesafe-actions'

import { BookingsReportsActionTypes } from '@app/store/pages/reports/bookingsReports/bookingsReports.constants'
import { BookingReportsDto } from '@shared/dto/reports.dto'
import { GetBookingsReportsFilters } from '@app/utils/api/types'

export const getBookingsReportsAction = () =>
  action(BookingsReportsActionTypes.GetBookingsReports)

export const getBookingsReportsSuccessAction = (response: BookingReportsDto) =>
  action(BookingsReportsActionTypes.GetBookingsReportsSuccess, response)

export const getBookingsReportsFailureAction = (error: unknown) =>
  action(BookingsReportsActionTypes.GetBookingsReportsFailure, { error })

export const setBookingsReportsFiltersAction = (
  filters: Partial<GetBookingsReportsFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(BookingsReportsActionTypes.SetBookingsReportsFilters, {
    filters,
    requestDebounceInMilliseconds,
  })

export const resetBookingsReportsFiltersAction = () =>
  action(BookingsReportsActionTypes.ResetBookingsReportsFilters)
