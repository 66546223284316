// withTranslation

import { AxiosResponse } from 'axios'
import produce from 'immer'

import {
  call,
  put,
  select,
  takeLatest,
  delay,
  StrictEffect,
  race,
  take,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { RequestsListActionTypes } from '@app/store/pages/requests/requestList/requestList.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { RootState } from '@app/store'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'

import * as actions from '@app/store/pages/requests/requestList/requestList.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

import {
  selectRequestsListFilters,
  selectRequestsListIsPolling,
  selectRequestsListLimit,
  selectRequestsListOrderBy,
  selectRequestsListOrderDirection,
  selectRequestsListPage,
} from '@app/store/pages/requests/requestList/requestList.selectors'

import {
  GetRequestsListParams,
  GetRequestsListResponse,
} from '@app/utils/api/types'
import { OfferFlags } from '@shared/enums'
import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'

const REQUESTS_LIST_POLLING_DELAY = 15_000

const getRequestsParams = (
  params: GetRequestsListParams,
): GetRequestsListParams => {
  const newParams = { ...params }

  if (newParams.flags?.includes(OfferFlags.NewMessage)) {
    newParams.onlyWithUnreadMessage = true
    newParams.flags = newParams.flags.filter((f) => f !== OfferFlags.NewMessage)
  }

  return newParams
}

function* getRequestsListDataSaga(page: number) {
  try {
    const limit = selectRequestsListLimit(yield select())
    const orderDirection = selectRequestsListOrderDirection(yield select())
    const filters = { ...selectRequestsListFilters(yield select()) }
    const orderBy = selectRequestsListOrderBy(yield select())
    const operator = selectSelectedOperator(yield select())
    const user = selectUserInfo(yield select())

    if (!operator || !user) {
      throw new Error('Operator and user are required')
    }

    const params: GetRequestsListParams = getRequestsParams({
      operator_id: String(operator.id),
      ...filters,
      airportCode: user.display_airport_identification || 'icao',
      orderBy,
      orderDirection,
      limit,
      page,
    })

    const { data }: AxiosResponse<GetRequestsListResponse> = yield call(
      api.getRequestsList,
      params,
    )

    yield put(actions.getRequestsListSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getRequestsList.default')
      default: 'errors.getRequestsList.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getRequestsListFailureAction(error))
  }
}

function* getInitialRequestsListDataSaga() {
  yield getRequestsListDataSaga(1)
}

function* getMoreRequestsListDataSaga() {
  const page = selectRequestsListPage(yield select())

  yield getRequestsListDataSaga(page + 1)
}

function* setRequestsListFiltersSaga(
  action: ReturnType<typeof actions.setRequestsListFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(RequestsListActionTypes.SetRequestsListFilters),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getRequestsListDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getRequestsListFailureAction(error))
  }
}

function* setRequestsListSortSaga() {
  try {
    yield put(actions.getRequestsListDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getRequestsListFailureAction(error))
  }
}

function* reloadRequestsListSaga() {
  const limit = selectRequestsListLimit(yield select())
  const page = selectRequestsListPage(yield select())
  const orderDirection = selectRequestsListOrderDirection(yield select())
  const filters = selectRequestsListFilters(yield select())
  const orderBy = selectRequestsListOrderBy(yield select())
  const operator = selectSelectedOperator(yield select())
  const user = selectUserInfo(yield select())

  if (!operator || !user) {
    throw new Error('Operator and user are required')
  }

  const params: GetRequestsListParams = getRequestsParams({
    operator_id: String(operator.id),
    ...filters,
    airportCode: user.display_airport_identification || 'icao',
    orderBy,
    orderDirection,
    limit: limit * page,
    page: 1,
  })

  try {
    const { data }: AxiosResponse<GetRequestsListResponse> = yield call(
      api.getRequestsList,
      produce(params, (draft) => {
        if (draft.limit && draft.page) {
          draft.limit = draft.limit * draft.page
        }
      }),
    )

    yield put(actions.reloadRequestsListSuccessAction(data))
  } catch (error) {
    console.error(error)

    yield put(actions.getRequestsListFailureAction(error))
  }
}

function* requestsListPollingSaga(): Generator<StrictEffect, void, RootState> {
  const isPolling = selectRequestsListIsPolling(yield select())

  if (!isPolling) {
    return
  }

  yield delay(REQUESTS_LIST_POLLING_DELAY)

  yield call(reloadRequestsListSaga)

  yield call(requestsListPollingSaga)
}

function* declineRequestSaga(
  action: ReturnType<typeof actions.declineRequestAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmations.declineConfirmation')
        i18nextKey: 'confirmations.declineConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      return
    }

    yield call(api.declineRequest, action.payload)

    yield call(reloadRequestsListSaga)

    yield put(
      addNotificationAction({
        // t('messages.declineRequest.success')
        i18nextKey: 'messages.declineRequest.success',
        type: 'success',
      }),
    )

    yield put(actions.declineRequestSuccessAction())
    yield put(dialogActions.closeConfirmationDialogAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.declineRequest.default')
      default: 'errors.declineRequest.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.declineRequestFailureAction(error))
  }
}

export default function* watchRequestsListSaga(): Generator {
  yield takeLatest(
    RequestsListActionTypes.GetRequestsListDataFirstPage,
    getInitialRequestsListDataSaga,
  )

  yield takeLatest(
    RequestsListActionTypes.ReloadRequestsList,
    reloadRequestsListSaga,
  )

  yield takeLatest(
    RequestsListActionTypes.GetRequestsListDataNextPage,
    getMoreRequestsListDataSaga,
  )

  yield takeLatest(
    RequestsListActionTypes.StartRequestsListPolling,
    requestsListPollingSaga,
  )

  yield takeLatest(
    RequestsListActionTypes.SetRequestsListFilters,
    setRequestsListFiltersSaga,
  )

  yield takeLatest(
    RequestsListActionTypes.SetRequestsListSort,
    setRequestsListSortSaga,
  )

  yield takeLatest(RequestsListActionTypes.DeclineRequest, declineRequestSaga)
}
