// withTranslation

import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { AircraftDetailActionTypes } from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'

import * as actions from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.actions'

function* getAircraftDetailSaga(
  action: ReturnType<typeof actions.getAircraftDetailAction>,
) {
  try {
    const { data } = yield call(api.getAircraft, action.payload)

    yield put(actions.getAircraftDetailSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getAircraftDetail.notFound')
      404: 'errors.getAircraftDetail.notFound',
      // t('errors.getAircraftDetail.default')
      default: 'errors.getAircraftDetail.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getAircraftDetailFailureAction(error))
  }
}

function* patchAircraftSaga(
  action: ReturnType<typeof actions.patchAircraftAction>,
) {
  try {
    yield call(api.patchAircraft, action.payload.id, action.payload.data)

    yield put(
      addNotificationAction({
        // t('messages.patchAircraft.success')
        i18nextKey: 'messages.patchAircraft.success',
        type: 'success',
      }),
    )

    yield put(actions.patchAircraftSuccessAction())
    yield put(actions.getAircraftDetailAction(action.payload.id))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchAircraft.notFound')
      404: 'errors.patchAircraft.notFound',
      // t('errors.patchAircraft.default')
      default: 'errors.patchAircraft.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchAircraftFailureAction(error))
  }
}

export default function* watchAircraftDetailSaga(): Generator {
  yield takeLatest(
    AircraftDetailActionTypes.GetAircraftDetail,
    getAircraftDetailSaga,
  )

  yield takeLatest(AircraftDetailActionTypes.PatchAircraft, patchAircraftSaga)
}
