import { action } from 'typesafe-actions'

import { ContactPersonsListActionTypes } from '@app/store/pages/clients/contactPersonsList/contactPersonsList.constants'
import { ContactPersonsListSortProperties } from '@app/store/pages/clients/contactPersonsList/contactPersonsList.types'

import {
  GetContactPersonsFilters,
  GetContactPersonsResponse,
} from '@app/utils/api/types'

export const getContactPersonsListDataFirstPageAction = () =>
  action(ContactPersonsListActionTypes.GetContactPersonsListDataFirstPage)

export const getContactPersonsListDataNextPageAction = () =>
  action(ContactPersonsListActionTypes.GetContactPersonsListDataNextPage)

export const getContactPersonsListSuccessAction = (
  response: GetContactPersonsResponse,
) =>
  action(ContactPersonsListActionTypes.GetContactPersonsListSuccess, response)

export const getContactPersonsListFailureAction = (error: unknown) =>
  action(ContactPersonsListActionTypes.GetContactPersonsListFailure, error)

export const setContactPersonsListSortAction = (
  sort: ContactPersonsListSortProperties,
) => action(ContactPersonsListActionTypes.SetContactPersonsListSort, sort)

export const setContactPersonsListFiltersAction = (
  filters: Partial<GetContactPersonsFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(ContactPersonsListActionTypes.SetContactPersonsListFilters, {
    filters,
    requestDebounceInMilliseconds,
  })

export const resetContactPersonsListFiltersAction = () =>
  action(ContactPersonsListActionTypes.ResetContactPersonsListFilters)

export const resetContactPersonsListAction = () =>
  action(ContactPersonsListActionTypes.ResetContactPersonsList)

export const reloadContactPersonsListAction = () =>
  action(ContactPersonsListActionTypes.ReloadContactPersonsList)

export const reloadContactPersonsListSuccessAction = (
  response: GetContactPersonsResponse,
) =>
  action(
    ContactPersonsListActionTypes.ReloadContactPersonsListSuccess,
    response,
  )
