// withTranslation

import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { OperatorSettingsActionTypes } from '@app/store/pages/settings/operatorSettings/operatorSettings.constants'
import { OperatorDto } from '@shared/dto/operator.dto'

import * as actions from '@app/store/pages/settings/operatorSettings/operatorSettings.actions'
import * as userOperatorsActions from '@app/store/core/userOperators/userOperators.actions'

function* patchOperatorSaga(
  action: ReturnType<typeof actions.patchOperatorAction>,
) {
  try {
    const { data }: AxiosResponse<OperatorDto> = yield call(
      api.patchOperator,
      action.payload.id,
      action.payload.partialOperatorDto,
    )

    yield put(
      addNotificationAction({
        // t('message.patchOperator.success')
        i18nextKey: 'message.patchOperator.success',
        type: 'success',
      }),
    )

    yield put(actions.patchOperatorSuccessAction(data))
    yield put(userOperatorsActions.getUserOperatorsAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchOperator.notFound')
      404: 'errors.patchOperator.notFound',
      // t('errors.patchOperator.default')
      default: 'errors.patchOperator.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchOperatorFailureAction(error))
  }
}

function* postOperatorSaga(
  action: ReturnType<typeof actions.postOperatorAction>,
) {
  try {
    const { data }: AxiosResponse<{ id: number }> = yield call(
      api.postOperator,
      action.payload.postOperatorDto,
    )

    yield put(
      addNotificationAction({
        // t('message.postOperator.success')
        i18nextKey: 'message.postOperator.success',
        type: 'success',
      }),
    )

    yield put(actions.postOperatorSuccessAction(data))
    yield put(userOperatorsActions.getUserOperatorsAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postOperator.default')
      default: 'errors.postOperator.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.postOperatorFailureAction(error))
  }
}

export default function* watchOperatorSettingsSaga(): Generator {
  yield takeLatest(OperatorSettingsActionTypes.PatchOperator, patchOperatorSaga)

  yield takeLatest(OperatorSettingsActionTypes.PostOperator, postOperatorSaga)
}
