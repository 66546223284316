import { action } from 'typesafe-actions'

import { ClientsActionTypes } from '@app/store/api/clients/clients.constants'
import { PostClientDto } from '@app/utils/api/types'

import * as DTOs from '@shared/dto'

export const postClientAction = (
  postClientDto: Omit<PostClientDto, 'operator_id'>,
) => action(ClientsActionTypes.PostClient, { postClientDto })

export const postClientSuccessAction = (response: DTOs.ClientDetailDto) =>
  action(ClientsActionTypes.PostClientSuccess, { response })

export const postClientFailureAction = (error: unknown) =>
  action(ClientsActionTypes.PostClientFailure, { error })
