import { action } from 'typesafe-actions'
import { CreateRequestActionTypes } from '@app/store/pages/requests/createRequest/createRequest.constants'

import { PostCreateOffersFromComputationParams } from '@app/utils/api/types'
import { OffersComputationInput } from '@shared/interfaces/Computation'
import { OffersComputationResultDto } from '@shared/dto/requests.dto'

export const triggerComputationPolling = (
  computationParams: Omit<OffersComputationInput, 'operator_id'>,
) =>
  action(CreateRequestActionTypes.TriggerComputationPolling, computationParams)

export const stopComputationPolling = () =>
  action(CreateRequestActionTypes.StopComputationPolling)

export const postComputationFailure = (error: unknown) =>
  action(CreateRequestActionTypes.PostComputationFailure, error)

export const watchComputation = (id: number) =>
  action(CreateRequestActionTypes.WatchComputation, id)

export const watchComputationSuccess = (
  computationId: number,
  data: OffersComputationResultDto,
) =>
  action(CreateRequestActionTypes.WatchComputationSuccess, {
    computationId,
    data,
  })

export const watchComputationFailure = (error: unknown) =>
  action(CreateRequestActionTypes.WatchComputationFailure, error)

export const resetCreateRequestStateAction = () =>
  action(CreateRequestActionTypes.ResetCreateRequestsState)

export const postCreateOffersFromComputation = (
  params: Pick<
    PostCreateOffersFromComputationParams,
    'aircraft_ids' | 'request_id'
  >,
) => action(CreateRequestActionTypes.PostCreateOffersFromComputation, params)

export const postCreateOffersFromComputationSuccess = () =>
  action(CreateRequestActionTypes.PostCreateOffersFromComputationSuccess)

export const postCreateOffersFromComputationFailure = (error: unknown) =>
  action(CreateRequestActionTypes.PostCreateOffersFromComputationFailure, error)
