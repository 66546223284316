// withTranslation

import { AxiosResponse } from 'axios'

import {
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { AircraftListActionTypes } from '@app/store/pages/fleet/aircraftList/aircraftList.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'
import { closeConfirmationDialogAction } from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

import * as actions from '@app/store/pages/fleet/aircraftList/aircraftList.actions'

import {
  selectAircraftListFilters,
  selectAircraftListLimit,
  selectAircraftListOrderBy,
  selectAircraftListOrderDirection,
  selectAircraftListPage,
} from '@app/store/pages/fleet/aircraftList/aircraftList.selectors'

import * as DTOs from '@shared/dto'

function* getAircraftListDataSaga(page: number) {
  try {
    const limit = selectAircraftListLimit(yield select())
    const orderDirection = selectAircraftListOrderDirection(yield select())
    const filters = selectAircraftListFilters(yield select())
    const orderBy = selectAircraftListOrderBy(yield select())
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const params: DTOs.AircraftSearchParams = {
      ...filters,
      orderBy,
      orderDirection: orderDirection || undefined,
      limit,
      page,
    }

    const { data }: AxiosResponse<DTOs.PaginatedList<DTOs.AircraftDetailDto>> =
      yield call(api.getAircraftList, params)

    yield put(actions.getAircraftListSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getAircraftList.default')
      default: 'errors.getAircraftList.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getAircraftListFailureAction(error))
  }
}

function* getInitialAircraftListDataSaga() {
  yield getAircraftListDataSaga(1)
}

function* getMoreAircraftListDataSaga() {
  const page = selectAircraftListPage(yield select())

  yield getAircraftListDataSaga(page + 1)
}

function* setAircraftListGetParametersSaga(
  action:
    | ReturnType<typeof actions.startSetAircraftListFiltersAction>
    | ReturnType<typeof actions.startSetAircraftListSortAction>,
) {
  try {
    yield put(actions.setAircraftListParametersAction(action.payload))
    yield put(actions.getAircraftListDataFirstPageAction())
  } catch (error) {
    yield put(actions.getAircraftListFailureAction(error))
  }
}

function* deleteAircraftSaga(
  action: ReturnType<typeof actions.deleteAircraftAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmations.deleteAircraftConfirmation')
        i18nextKey: 'confirmations.deleteAircraftConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      return
    }

    yield call(api.deleteAircraft, action.payload)

    yield put(actions.deleteAircraftSuccessAction(action.payload))

    yield put(
      addNotificationAction({
        // t('messages.deleteAircraft.success')
        i18nextKey: 'messages.deleteAircraft.success',
        type: 'success',
      }),
    )

    yield put(closeConfirmationDialogAction())
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteAircraft.forbidden')
      403: 'errors.deleteAircraft.forbidden',
      // t('errors.deleteAircraft.notFound')
      404: 'errors.deleteAircraft.notFound',
      // t('errors.deleteAircraft.default')
      default: 'errors.deleteAircraft.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    if (error?.response?.status && error?.response?.status === 403) {
      yield put(closeConfirmationDialogAction())
    } else {
      yield put(actions.getAircraftListFailureAction(error))
    }
  }
}

export default function* watchAircraftListSaga(): Generator {
  yield takeLatest(
    AircraftListActionTypes.GetAircraftListDataFirstPage,
    getInitialAircraftListDataSaga,
  )

  yield takeLatest(
    AircraftListActionTypes.GetAircraftListDataNextPage,
    getMoreAircraftListDataSaga,
  )

  yield takeLatest(
    [
      AircraftListActionTypes.StartSetAircraftListFilters,
      AircraftListActionTypes.StartSetAircraftListSort,
    ],
    setAircraftListGetParametersSaga,
  )

  yield takeEvery(AircraftListActionTypes.DeleteAircraft, deleteAircraftSaga)
}
