// withTranslation

import { call, put, race, take, takeLatest } from 'redux-saga/effects'
import axios from 'axios'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ContactPersonsActionTypes } from '@app/store/api/contactPersons/contactPersons.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'
import { PostContactPersonErrorResponse } from '@app/utils/api/types'

import * as actions from '@app/store/api/contactPersons/contactPersons.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

function* postContactPersonSaga(
  action: ReturnType<typeof actions.postContactPersonAction>,
) {
  try {
    const { data } = yield call(
      api.postContactPerson,
      action.payload.postContactPersonDto,
    )

    yield put(
      addNotificationAction({
        // t('messages.postContactPerson.success')
        i18nextKey: 'messages.postContactPerson.success',
        type: 'success',
      }),
    )

    yield put(actions.postContactPersonSuccessAction(data))
  } catch (error) {
    console.error(error)

    const getIsPostContactPersonErrorResponse = (
      error: unknown,
    ): error is PostContactPersonErrorResponse =>
      !!(error as PostContactPersonErrorResponse).existing_contact_person_id

    if (
      axios.isAxiosError(error) &&
      error.response?.status === 409 &&
      getIsPostContactPersonErrorResponse(error.response?.data)
    ) {
      yield put(
        dialogActions.openConfirmationDialogAction({
          // t('confirmation.postContactPersonConflict')
          i18nextKey: 'confirmation.postContactPersonConflict',
        }),
      )

      const { cancel } = yield race({
        submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
        cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
      })

      if (cancel) {
        yield put(actions.cancelPostContactPersonLoadingAction())
        return
      }

      try {
        yield put(
          actions.patchContactPersonAction(
            error.response.data.existing_contact_person_id,
            action.payload.postContactPersonDto,
          ),
        )

        yield put(
          actions.postContactPersonSuccessAction({
            id: error.response.data.existing_contact_person_id,
          }),
        )

        yield put(dialogActions.closeConfirmationDialogAction())

        return
      } catch (error) {
        yield put(
          addNotificationAction({
            // t('errors.postContactPerson.default')
            i18nextKey: 'errors.postContactPerson.default',
            type: 'error',
          }),
        )

        yield put(actions.postContactPersonFailureAction(error))
      }

      return
    }

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postContactPerson.conflict')
      409: 'errors.postContactPerson.conflict',
      // t('errors.postContactPerson.default')
      default: 'errors.postContactPerson.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.postContactPersonFailureAction(error))
  }
}

function* patchContactPersonSaga(
  action: ReturnType<typeof actions.patchContactPersonAction>,
) {
  try {
    const { data } = yield call(
      api.patchContactPerson,
      action.payload.id,
      action.payload.partialContactPersonDto,
    )

    yield put(
      addNotificationAction({
        // t('messages.patchContactPerson.success')
        i18nextKey: 'messages.patchContactPerson.success',
        type: 'success',
      }),
    )

    yield put(actions.patchContactPersonSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchContactPerson.notFound')
      404: 'errors.patchContactPerson.notFound',
      // t('errors.patchContactPerson.conflict')
      409: 'errors.patchContactPerson.conflict',
      // t('errors.patchContactPerson.default')
      default: 'errors.patchContactPerson.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchContactPersonFailureAction(error))
  }
}

export default function* watchContactPersonDetailSaga(): Generator {
  yield takeLatest(
    ContactPersonsActionTypes.PostContactPerson,
    postContactPersonSaga,
  )

  yield takeLatest(
    ContactPersonsActionTypes.PatchContactPerson,
    patchContactPersonSaga,
  )
}
