// withTranslation

import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { UserInfoActionTypes } from '@app/store/core/userInfo/userInfo.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { setWeekStart } from '@app/utils/dayjsUtils'
import { DeferrableAction } from '@app/store/middlewares/deferred.middleware'

import * as actions from '@app/store/core/userInfo/userInfo.actions'

function* GetMyUserInfoSaga() {
  try {
    const { data } = yield call(api.getMyUser)

    setWeekStart(data.display_week_start)

    yield put(actions.getMyUserInfoSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.GetMyUserInfo.notFound')
      404: 'errors.GetMyUserInfo.notFound',
      // t('errors.GetMyUserInfo.default')
      default: 'errors.GetMyUserInfo.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getMyUserInfoFailureAction(error))
  }
}

function* GetUserInfoSaga(
  action: ReturnType<typeof actions.getUserInfoAction>,
) {
  try {
    const { data } = yield call(api.getUser, action.payload)

    setWeekStart(data.display_week_start)

    yield put(actions.getUserInfoSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.GetMyUserInfo.notFound')
      404: 'errors.GetUserInfo.notFound',
      // t('errors.GetMyUserInfo.default')
      default: 'errors.GetUserInfo.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getUserInfoFailureAction(error))
  }
}

function* patchMyUserSaga(
  action: ReturnType<typeof actions.patchMyUserAction>,
) {
  try {
    const { data } = yield call(api.patchMyUser, action.payload)

    yield put(
      addNotificationAction({
        // t('messages.patchMyUser.success')
        i18nextKey: 'messages.patchMyUser.success',
        type: 'success',
      }),
    )

    yield put(actions.patchMyUserSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchMyUser.notFound')
      404: 'errors.patchMyUser.notFound',
      // t('errors.patchMyUser.default')
      default: 'errors.patchMyUser.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchMyUserFailureAction(error))
  }
}

function* patchUserSaga(action: ReturnType<typeof actions.patchUserAction>) {
  try {
    const { data } = yield call(
      api.patchUser,
      action.payload.id,
      action.payload.partialUser,
    )

    yield put(
      addNotificationAction({
        i18nextKey: 'messages.patchUser.success',
        type: 'success',
      }),
    )

    yield put(actions.getMyUserInfoAction())
    yield put(actions.patchUserSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchMyUser.notFound')
      404: 'errors.patchUser.notFound',
      // t('errors.patchMyUser.default')
      default: 'errors.patchUser.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchUserFailureAction(error))
  }
}

function* putMyPasswordSaga(
  action: DeferrableAction<ReturnType<typeof actions.putMyPasswordAction>>,
) {
  try {
    yield call(api.putMyPassword, action.payload)

    yield put(
      addNotificationAction({
        // t('messages.putMyPassword.success')
        i18nextKey: 'messages.putMyPassword.success',
        type: 'success',
      }),
    )

    yield put(actions.putMyPasswordSuccessAction())

    action.meta.promise.resolve()
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.putMyPassword.forbidden')
      403: 'errors.putMyPassword.forbidden',
      // t('errors.putMyPassword.notFound')
      404: 'errors.putMyPassword.notFound',
      // t('errors.putMyPassword.default')
      default: 'errors.putMyPassword.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.putMyPasswordFailureAction(error))

    action.meta.promise.reject()
  }
}

export default function* watchUserInfoSaga(): Generator {
  yield takeLatest(UserInfoActionTypes.GetMyUserInfo, GetMyUserInfoSaga)

  yield takeLatest(UserInfoActionTypes.GetUserInfo, GetUserInfoSaga)

  yield takeLatest(UserInfoActionTypes.PatchMyUser, patchMyUserSaga)

  yield takeLatest(UserInfoActionTypes.PatchUser, patchUserSaga)

  yield takeLatest(UserInfoActionTypes.PutMyPassword, putMyPasswordSaga)
}
