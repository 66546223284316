// withTranslation

import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { RebookingActionTypes } from '@app/store/pages/requests/rebooking/rebooking.constants'
import { reloadRequestDetailSaga } from '@app/store/pages/requests/requestDetail/requestDetail.sagas'
import { api } from '@app/utils/api/api'
import { setOpenOfferIdAction } from '@app/store/pages/requests/requestDetail/requestDetail.actions'
import { OfferStatuses } from '@shared/enums'
import * as actions from '@app/store/pages/requests/rebooking/rebooking.actions'

import {
  selectOpenOfferId,
  selectRequestDetailData,
} from '@app/store/pages/requests/requestDetail/requestDetail.selectors'

// @todo Bump TS version to >= 4.5
// @see https://stackoverflow.com/a/49889856/3210641
type Awaited<T> = T extends PromiseLike<infer U> ? U : T

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiResponse<T extends (...args: any) => any> = Awaited<ReturnType<T>>

function* offerRebookingPollingSaga() {
  const openOfferId = selectOpenOfferId(yield select())
  const requestDetailData = selectRequestDetailData(yield select())

  const openOffer = requestDetailData?.offers.find(
    (offer) => offer.id === openOfferId,
  )

  if (!openOffer) {
    throw new Error(`Open offer doesn't exist`)
  }

  if (openOffer.status === OfferStatuses.Processing) {
    yield delay(1000)

    yield call(reloadRequestDetailSaga)

    yield call(offerRebookingPollingSaga)

    return
  }

  yield put(actions.rebookOfferSuccessAction())
}

function* rebookOfferSaga(
  action: ReturnType<typeof actions.initRebookingAction>,
) {
  try {
    const { data }: ApiResponse<typeof api.rebookOffer> = yield call(
      api.rebookOffer,
      action.payload.offerId,
      action.payload.rebookingRequests,
    )

    yield call(reloadRequestDetailSaga)

    yield put(setOpenOfferIdAction(data.id))

    yield call(offerRebookingPollingSaga)
  } catch (error) {
    console.log(error)

    yield put(actions.setRebookingFailureAction(error))
  }
}

export default function* watchOfferDetailSaga(): Generator {
  yield takeLatest(RebookingActionTypes.InitRebooking, rebookOfferSaga)
}
