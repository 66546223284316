import { action } from 'typesafe-actions'

import { ContactPersonsActionTypes } from '@app/store/pages/clientDetail/contactPersons/contactPersons.constants'
import { ContactPersonsSortProperties } from '@app/store/pages/clientDetail/contactPersons/contactPersons.types'

import {
  GetContactPersonsFilters,
  GetContactPersonsResponse,
} from '@app/utils/api/types'

export const getContactPersonsDataFirstPageAction = () =>
  action(ContactPersonsActionTypes.GetContactPersonsDataFirstPage)

export const getContactPersonsDataNextPageAction = () =>
  action(ContactPersonsActionTypes.GetContactPersonsDataNextPage)

export const getContactPersonsSuccessAction = (
  response: GetContactPersonsResponse,
) => action(ContactPersonsActionTypes.GetContactPersonsSuccess, response)

export const getContactPersonsFailureAction = (error: unknown) =>
  action(ContactPersonsActionTypes.GetContactPersonsFailure, error)

export const setContactPersonsSortAction = (
  sort: ContactPersonsSortProperties,
) => action(ContactPersonsActionTypes.SetContactPersonsSort, sort)

export const setContactPersonsFiltersAction = (
  filters: Partial<GetContactPersonsFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(ContactPersonsActionTypes.SetContactPersonsFilters, {
    filters,
    requestDebounceInMilliseconds,
  })

export const resetContactPersonsFiltersAction = () =>
  action(ContactPersonsActionTypes.ResetContactPersonsFilters)

export const resetContactPersonsAction = () =>
  action(ContactPersonsActionTypes.ResetContactPersons)

export const reloadContactPersonsAction = () =>
  action(ContactPersonsActionTypes.ReloadContactPersons)

export const reloadContactPersonsSuccessAction = (
  response: GetContactPersonsResponse,
) => action(ContactPersonsActionTypes.ReloadContactPersonsSuccess, response)

export const deleteContactPersonAction = (id: number) =>
  action(ContactPersonsActionTypes.DeleteContactPerson, { id })

export const deleteContactPersonSuccessAction = () =>
  action(ContactPersonsActionTypes.DeleteContactPersonSuccess)

export const deleteContactPersonFailureAction = (error: unknown) =>
  action(ContactPersonsActionTypes.DeleteContactPersonFailure, { error })

export const cancelContactPersonDeleteAction = () =>
  action(ContactPersonsActionTypes.CancelContactPersonDelete)
