// withTranslation

import axios, { AxiosResponse } from 'axios'

import {
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  race,
  select,
  SelectEffect,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import { getErrorMessage } from '@app/utils/errorHandling'
import * as notificationActions from '@app/store/ui/notifications/notifications.actions'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { RequestDetailActionTypes } from '@app/store/pages/requests/requestDetail/requestDetail.constants'
import { api } from '@app/utils/api/api'
import { selectRequestsListData } from '@app/store/pages/requests/requestList/requestList.selectors'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { RootState } from '@app/store'
import { ChatMessageDto, PaginatedChatListDto } from '@shared/dto/chat.dto'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'

import {
  selectOpenOfferId,
  selectRequestDetailData,
} from '@app/store/pages/requests/requestDetail/requestDetail.selectors'

import {
  OfferDetailDto,
  OfferRecalculationResultDto,
} from '@shared/dto/requests.dto'

import {
  ChatMessageTypes,
  ComputationStatuses,
  ComputationTypes,
  OfferStatuses,
} from '@shared/enums'

import {
  GetChatParams,
  GetScheduleParams,
  GetScheduleResponse,
  PostComputationResponse,
} from '@app/utils/api/types'

import * as actions from '@app/store/pages/requests/requestDetail/requestDetail.actions'
import * as requestListActions from '@app/store/pages/requests/requestList/requestList.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

const COMPUTATION_POLLING_DELAY = 1000

function* getRequestDetailSaga(
  action: ReturnType<typeof actions.getRequestDetailAction>,
) {
  try {
    const { data } = yield call(
      api.getRequest,
      action.payload.id,
      action.payload.operatorId,
    )

    yield put(actions.getRequestDetailSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getRequestDetail.notFound')
      404: 'errors.getRequestDetail.notFound',
      // t('errors.getRequestDetail.default')
      default: 'errors.getRequestDetail.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getRequestDetailFailureAction(error))
  }
}

export function* reloadRequestDetailSaga() {
  try {
    const request = selectRequestDetailData(yield select())

    if (!request) {
      return
    }

    const { data } = yield call(api.getRequest, request.id)

    yield put(actions.getRequestDetailSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getRequestDetail.notFound')
      404: 'errors.getRequestDetail.notFound',
      // t('errors.getRequestDetail.default')
      default: 'errors.getRequestDetail.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getRequestDetailFailureAction(error))
  }
}

function* checkOpenOfferDetailState(
  action: ReturnType<typeof actions.checkOpenOfferDetailStateAction>,
) {
  const request = selectRequestDetailData(yield select())

  const offer = request?.offers.find(
    (offer) => offer.id === action.payload.openOfferId,
  )

  if (offer?.status !== OfferStatuses.New) {
    return
  }

  yield put(actions.updateOfferStatusAction(offer.id, OfferStatuses.Unhandled))
}

function* updateOfferStatus(
  action: ReturnType<typeof actions.updateOfferStatusAction>,
) {
  const request = selectRequestDetailData(yield select())

  if (!request) {
    return
  }

  const offer = request?.offers.find(
    (offer) => offer.id === action.payload.offerId,
  )

  if (!offer) {
    return
  }

  const isInStatusRelevantForEmail = [
    OfferStatuses.Quoted,
    OfferStatuses.Booked,
  ].includes(action.payload.nextStatus)

  const hasContactPerson = request.contact_person_id && request.client_id

  if (
    action.payload.shouldSendEmail &&
    isInStatusRelevantForEmail &&
    !hasContactPerson
  ) {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmation.bookWithoutContactPerson')
        // t('confirmation.quoteWithoutContactPerson')
        i18nextKey:
          action.payload.nextStatus === OfferStatuses.Booked
            ? 'confirmation.bookWithoutContactPerson'
            : 'confirmation.quoteWithoutContactPerson',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    yield put(dialogActions.closeConfirmationDialogAction())

    if (cancel) {
      return
    }
  }

  try {
    yield call(
      api.updateOfferStatus,
      offer.id,
      action.payload.nextStatus,
      action.payload.shouldSendEmail,
      action.payload.userNote,
      action.payload.cancellationFee,
      action.payload.shouldSendToAvinode,
    )

    yield put(
      addNotificationAction({
        // t('messages.updateOfferStatus.success')
        i18nextKey: 'messages.updateOfferStatus.success',
        type: 'success',
      }),
    )

    // @todo More efficient way of doing this
    yield put(requestListActions.reloadRequestsListAction())
    yield put(actions.reloadRequestDetailAction())
    yield put(actions.getChatAction(request.id))
  } catch (error) {
    // @todo Global error trap ???
    // @todo Omit in production
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.updateOfferStatus.conflict')
      409: 'errors.updateOfferStatus.conflict',
      // t('errors.updateOfferStatus.default')
      default: 'errors.updateOfferStatus.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }
  }
}

function* patchOfferSaga(action: ReturnType<typeof actions.patchOfferAction>) {
  try {
    yield call(
      api.patchOffer,
      action.payload.offerId,
      action.payload.partialOffer,
    )

    yield put(
      notificationActions.addNotificationAction({
        // t('messages.patchOffer.success')
        i18nextKey: 'messages.patchOffer.success',
        type: 'success',
      }),
    )

    // @todo Better place to do this ???
    // yield put(actions.resetOfferDetailStateAction())

    yield put(actions.patchOfferSuccessAction())
    yield put(actions.resetIgnoreLegsFromOptimizationStateAction())
    yield put(actions.resetRecalculationStateAction())

    const requests = selectRequestsListData(yield select())

    if (!requests) {
      return
    }

    yield put(requestListActions.reloadRequestsListAction())
    yield put(actions.reloadRequestDetailAction())
  } catch (error) {
    yield put(actions.patchOfferActionFailureAction(error))

    // @todo Global error trap ???
    // @todo Omit in production
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchOffer.default')
      default: 'errors.patchOffer.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }
  }
}

function* deleteOfferSaga(
  action: ReturnType<typeof actions.deleteOfferAction>,
) {
  try {
    yield call(api.deleteOffer, action.payload.offerId)

    yield put(
      notificationActions.addNotificationAction({
        // t('messages.deleteOffer.success')
        i18nextKey: 'messages.deleteOffer.success',
        type: 'success',
      }),
    )

    yield put(actions.deleteOfferSuccessAction(action.payload.offerId))
    yield put(actions.reloadRequestDetailAction())
  } catch (error) {
    // @todo Global error trap ???
    // @todo Omit in production
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteOffer.default')
      default: 'errors.deleteOffer.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.deleteOfferFailureAction(error))
  }
}

// @todo Make util and reuse in createRequest saga
function* watchComputation(
  computationId: number,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<OfferRecalculationResultDto>
> {
  try {
    const { data }: AxiosResponse<OfferRecalculationResultDto> = yield call(
      api.getComputation,
      computationId,
    )

    if (data.status === ComputationStatuses.Finished) {
      yield put(actions.recalculateOfferSuccessAction(data))

      return
    }

    yield delay(COMPUTATION_POLLING_DELAY)

    yield call(watchComputation, computationId)
  } catch (error) {
    let errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.watchComputation.default')
      default: 'errors.watchComputation.default',
    })

    if (axios.isAxiosError(error) && error?.response?.data.status) {
      // @todo Add missing statuses ???
      // t('errors.watchComputation.overlapping_leg')
      // t('errors.watchComputation.capacity_exceeded')
      // t('errors.watchComputation.flight_range_exceeded')
      errorMessageKey = `errors.watchComputation.${error.response.data.status}`
    }

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.recalculateOfferFailureAction(error))
  }
}

function* recalculateOfferSaga(
  action: ReturnType<typeof actions.recalculateOfferAction>,
) {
  const operator = selectSelectedOperator(yield select())

  if (!operator) {
    throw new Error('Operator is required')
  }

  try {
    const { data }: AxiosResponse<PostComputationResponse> = yield call(
      api.postComputation,
      {
        operator_id: operator.id,
        type: action.payload.isBookedRecalculation
          ? ComputationTypes.OfferBookedRecalculation
          : ComputationTypes.OfferRecalculation,
        aircraft_id: action.payload.aircraftId,
        offer_id: action.payload.offerId,
        requests: action.payload.requests,
        removed_leg_ids: action.payload.removedLegIds ?? [],
      },
    )

    yield call(watchComputation, data.id)
  } catch (error) {
    // @todo Global error trap ???
    // @todo Omit in production
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postComputation.default')
      default: 'errors.postComputation.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }
  }
}

function* patchRequestSaga(
  action: ReturnType<typeof actions.patchRequestAction>,
) {
  try {
    yield call(
      api.patchRequest,
      action.payload.requestId,
      action.payload.partialRequest,
    )

    yield put(
      notificationActions.addNotificationAction({
        // t('messages.patchRequest.success')
        i18nextKey: 'messages.patchRequest.success',
        type: 'success',
      }),
    )

    yield put(actions.patchRequestActionSuccessAction())
    yield put(actions.reloadRequestDetailAction())

    const requests = selectRequestsListData(yield select())

    if (!requests) {
      return
    }

    yield put(requestListActions.reloadRequestsListAction())
  } catch (error) {
    yield put(actions.patchRequestActionFailureAction(error))

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchRequest.default')
      default: 'errors.patchRequest.default',
    })

    if (errorMessageKey) {
      yield put(
        notificationActions.addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }
  }
}

function* getOfferScheduleSaga(
  action: ReturnType<typeof actions.getOfferScheduleAction>,
) {
  try {
    const { registrationCode, from, to } = action.payload

    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const params: GetScheduleParams = {
      /**
       * @todo Load recursively instead of setting high limit
       */
      limit: 100,
      operator_id: operator.id,
      aircraft: registrationCode,
      arrival_date_start: from,
      departure_date_end: to,
    }

    const { data }: AxiosResponse<GetScheduleResponse> = yield call(
      api.getScheduleList,
      params,
    )

    yield put(actions.getOfferScheduleSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getOfferSchedule.default')
      default: 'errors.getOfferSchedule.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getOfferScheduleFailureAction(error))
  }
}

function* getLegEditorScheduleSaga(
  action: ReturnType<typeof actions.getLegEditorScheduleAction>,
) {
  try {
    const { registrationCode, from, to } = action.payload

    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const params: GetScheduleParams = {
      /**
       * @todo Load recursively instead of setting high limit
       */
      limit: 100,
      operator_id: operator.id,
      aircraft: registrationCode,
      arrival_date_start: from,
      departure_date_end: to,
    }

    const { data }: AxiosResponse<GetScheduleResponse> = yield call(
      api.getScheduleList,
      params,
    )

    yield put(actions.getLegEditorScheduleSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getLegEditorSchedule.default')
      default: 'errors.getLegEditorSchedule.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getLegEditorScheduleFailureAction(error))
  }
}

function* getInitialChatSaga(
  requestId: number,
  limit = 20,
  page?: number,
  total?: number,
  messages?: ChatMessageDto[],
): Generator<
  PutEffect | SelectEffect | Generator | CallEffect,
  void,
  AxiosResponse<PaginatedChatListDto>
> {
  if (messages && messages.length === total) {
    yield put(actions.getChatSuccessAction(messages))

    return
  }

  try {
    if (!requestId) {
      throw new Error('Request needs to be selected')
    }

    const params: GetChatParams = {
      page: page ? page + 1 : 1,
      request_id: requestId,
      limit,
    }

    const { data } = yield call(api.getChat, params)

    yield getInitialChatSaga(
      requestId,
      data.limit,
      data.page,
      data.total,
      messages ? [...messages, ...data.data] : data.data,
    )
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getChat.default')
      default: 'errors.getChat.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getChatFailureAction(error))
  }
}

function* getChatSaga(
  action: ReturnType<typeof actions.getChatAction>,
): Generator<SelectEffect | Generator, void, RootState> {
  yield getInitialChatSaga(action.payload.requestId)
}

function* postChatMessageSaga(
  action: ReturnType<typeof actions.postChatMessageAction>,
) {
  try {
    const openRequest = selectRequestDetailData(yield select())

    if (!openRequest?.id) {
      throw new Error('Request needs to be selected')
    }

    const { data } = yield call(api.postChatMessage, {
      type: ChatMessageTypes.UserMessage,
      request_id: openRequest.id,
      content: action.payload.content,
      offer_id: action.payload.offer_id,
    })

    yield put(actions.postChatMessageSuccessAction(data))
    yield put(actions.getChatAction(openRequest.id))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postChatMessage.400')
      400: 'errors.postChatMessage.400',
      // t('errors.postChatMessage.404')
      404: 'errors.postChatMessage.404',
      // t('errors.postChatMessage.default')
      default: 'errors.postChatMessage.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.postChatMessageFailureAction(error))
  }
}

function* markMessagesAsReadSaga(
  action: ReturnType<typeof actions.markChatMessagesAsReadAction>,
) {
  try {
    yield call(api.postMarkChatMessageAsRead, action.payload)

    yield put(requestListActions.reloadRequestsListAction())
  } catch (error) {
    // Do nothing
  }
}

function* offerCancellationPollingSaga() {
  const openOfferId = selectOpenOfferId(yield select())
  const requestDetailData = selectRequestDetailData(yield select())

  const openOffer = requestDetailData?.offers.find(
    (offer) => offer.id === openOfferId,
  )

  if (!openOffer) {
    throw new Error(`Open offer doesn't exist`)
  }

  if (openOffer.status === OfferStatuses.Processing) {
    yield delay(1000)

    yield call(reloadRequestDetailSaga)

    yield call(offerCancellationPollingSaga)

    return
  }

  yield put(actions.cancelOfferSuccessAction())
}

function* cancelOfferSaga(
  action: ReturnType<typeof actions.cancelOfferAction>,
) {
  try {
    const { data }: AxiosResponse<OfferDetailDto> = yield call(
      api.cancelOffer,
      action.payload.id,
    )

    yield call(reloadRequestDetailSaga)

    yield put(actions.setOpenOfferIdAction(data.id))

    yield call(offerCancellationPollingSaga)
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.cancelOffer.default')
      default: 'errors.cancelOffer.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.cancelOfferFailureAction(error))
  }
}

function* getOfferRelatedCustomRoutesSaga(
  action: ReturnType<typeof actions.getOfferRelatedCustomRoutesAction>,
) {
  try {
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const { data: customRoutes } = yield call(api.getCustomRoutes, {
      ...action.payload,
      operator_id: operator.id,
      page: 1,
      limit: 100,
    })

    yield put(
      actions.getOfferRelatedCustomRoutesSuccessAction(customRoutes.data),
    )
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getOfferRelatedCustomRoutes.default')
      default: 'errors.getOfferRelatedCustomRoutes.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getOfferRelatedCustomRoutesFailureAction(error))
  }
}

function* getOfferRelatedAirportFeesSaga(
  action: ReturnType<typeof actions.getOfferRelatedAirportFees>,
) {
  try {
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const { data: airportFees } = yield call(api.getAirportFees, {
      aircraft_id: action.payload.aircraftId,
      matchers: action.payload.airportIcaoCodes,
      page: 1,
      limit: 100,
    })

    yield put(actions.getOfferRelatedAirportFeesSuccessAction(airportFees.data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getOfferRelatedAirportFees.default')
      default: 'errors.getOfferRelatedAirportFees.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getOfferRelatedAirportFeesFailureAction(error))
  }
}

function* ignoreLegsFromOptimizationSaga(
  action: ReturnType<typeof actions.ignoreLegsFromOptimizationAction>,
) {
  try {
    const operator = selectSelectedOperator(yield select())
    const request = selectRequestDetailData(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    if (!request) {
      throw new Error('Request is required')
    }

    const { data }: AxiosResponse<PostComputationResponse> = yield call(
      api.postComputation,
      action.payload,
    )

    yield call(watchIgnoreLegsFromOptimization, data.id)
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.ignoreLegsFromOptimization.default')
      default: 'errors.ignoreLegsFromOptimization.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.ignoreLegsFromOptimizationFailureAction(error))
  }
}

function* watchIgnoreLegsFromOptimization(computationId: number) {
  try {
    const { data }: AxiosResponse<OfferRecalculationResultDto> = yield call(
      api.getComputation,
      computationId,
    )

    if (data.status === ComputationStatuses.Finished) {
      yield put(actions.ignoreLegsFromOptimizationSuccessAction(data))

      return
    }

    yield delay(COMPUTATION_POLLING_DELAY)

    yield call(watchIgnoreLegsFromOptimization, computationId)
  } catch (error) {
    let errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.watchComputation.default')
      default: 'errors.watchComputation.default',
    })

    if (axios.isAxiosError(error) && error?.response?.data.status) {
      // @todo Add missing statuses ???
      // t('errors.watchComputation.overlapping_leg')
      // t('errors.watchComputation.capacity_exceeded')
      // t('errors.watchComputation.flight_range_exceeded')
      errorMessageKey = `errors.watchComputation.${error.response.data.status}`
    }

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.ignoreLegsFromOptimizationFailureAction(error))
  }
}

export default function* watchOfferDetailSaga(): Generator {
  yield takeEvery(RequestDetailActionTypes.CancelOffer, cancelOfferSaga)

  yield takeEvery(
    RequestDetailActionTypes.CheckOpenOfferDetailState,
    checkOpenOfferDetailState,
  )

  yield takeEvery(RequestDetailActionTypes.PatchOffer, patchOfferSaga)

  yield takeEvery(RequestDetailActionTypes.UpdateOfferStatus, updateOfferStatus)

  yield takeEvery(RequestDetailActionTypes.DeleteOffer, deleteOfferSaga)

  yield takeEvery(
    RequestDetailActionTypes.RecalculateOffer,
    recalculateOfferSaga,
  )

  yield takeLatest(RequestDetailActionTypes.PatchRequest, patchRequestSaga)

  yield takeLatest(
    RequestDetailActionTypes.GetOfferSchedule,
    getOfferScheduleSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.GetLegEditorSchedule,
    getLegEditorScheduleSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.GetRequestDetail,
    getRequestDetailSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.ReloadRequestDetail,
    reloadRequestDetailSaga,
  )

  yield takeLatest(RequestDetailActionTypes.GetChat, getChatSaga)

  yield takeLatest(
    RequestDetailActionTypes.PostChatMessage,
    postChatMessageSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.MarkChatMessagesAsRead,
    markMessagesAsReadSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.GetOfferRelatedCustomRoutes,
    getOfferRelatedCustomRoutesSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.GetOfferRelatedAirportFees,
    getOfferRelatedAirportFeesSaga,
  )

  yield takeLatest(
    RequestDetailActionTypes.IgnoreLegsFromOptimization,
    ignoreLegsFromOptimizationSaga,
  )
}
