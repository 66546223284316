import {
  Actions,
  LegTypes,
  OffersComputationOutputItemsStatuses,
  UserRegistrationSteps,
  UserRegistrationTypes,
  UserRoles,
} from '@shared/enums'

export const MINUTES_IN_HOUR = 60

// @todo More meaningful name ???
export const SIMPLE_DATE_FORMAT = 'DD.MM.YYYY'

export const SIMPLE_TIME_FORMAT = 'HH:mm'

export const SIMPLE_DATE_AND_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const SEMANTIC_DURATION_FORMAT = 'H[h] m[m]'

export const SIMPLE_DURATION_FORMAT = 'HH:mm'

export const DEFAULT_DISTANCE_UNIT = 'NM'

export const DEFAULT_NUMBER_FORMAT = '### ##0.##'

export const DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY = '### ##0.##'

export const DEFAULT_ORDER_BY = 'created_at'

export const DEFAULT_ORDER_DIRECTION = 'asc'

export const DEFAULT_LIMIT = 10

export const DEFAULT_PAGE = 1

export const REQUEST_TRIP_ID_LENGTH = 6

export const REQUEST_TRIP_ID_PREFIX = 'STR-'

export const AVINODE_REQUEST_TRIP_ID_PREFIX = 'AVN-'

export const WEB_REQUEST_TRIP_ID_PREFIX = 'WEB-'

export const INVALID_COMPUTATION_OUTPUT_ITEM_STATUSES = [
  OffersComputationOutputItemsStatuses.CapacityExceeded,
  OffersComputationOutputItemsStatuses.FlightRangeExceeded,
  OffersComputationOutputItemsStatuses.ScheduleConflict,
  /**
   * @todo [Tech] Deprecate OverlappingLeg status - remove from existing
   *              computations
   */
  OffersComputationOutputItemsStatuses.OverlappingLeg,
]

export const REQUIRED_LEG_TYPES: LegTypes[] = [
  LegTypes.Ferry,
  LegTypes.Occupied,
  LegTypes.Outage,
]

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

export const ALL_USER_ROLES = Object.values(UserRoles)

export const PERMISSIONS: Record<Actions, UserRoles[]> = {
  [Actions.CreateFuelCost]: ALL_USER_ROLES,
  [Actions.GetFuelCost]: ALL_USER_ROLES,
  [Actions.UpdateFuelCost]: ALL_USER_ROLES,
  [Actions.DeleteFuelCost]: ALL_USER_ROLES,

  [Actions.CreateOvernightFee]: ALL_USER_ROLES,
  [Actions.GetOvernightFee]: ALL_USER_ROLES,
  [Actions.UpdateOvernightFee]: ALL_USER_ROLES,
  [Actions.DeleteOvernightFee]: ALL_USER_ROLES,

  [Actions.CreateAirportFee]: ALL_USER_ROLES,
  [Actions.GetAirportFee]: ALL_USER_ROLES,
  [Actions.UpdateAirportFee]: ALL_USER_ROLES,
  [Actions.DeleteAirportFee]: ALL_USER_ROLES,
  [Actions.ImportAirportFees]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
  ],

  [Actions.CreateCustomRoute]: ALL_USER_ROLES,
  [Actions.GetCustomRoute]: ALL_USER_ROLES,
  [Actions.UpdateCustomRoute]: ALL_USER_ROLES,
  [Actions.DeleteCustomRoute]: ALL_USER_ROLES,

  [Actions.CreateAircraft]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
    UserRoles.Manager,
  ],
  [Actions.GetAircraft]: ALL_USER_ROLES,
  [Actions.UpdateAircraft]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
    UserRoles.Manager,
  ],
  [Actions.DeleteAircraft]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
    UserRoles.Manager,
  ],
  [Actions.CopyAircraft]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
    UserRoles.Manager,
  ],

  [Actions.CreateClient]: ALL_USER_ROLES,
  [Actions.GetClient]: ALL_USER_ROLES,
  [Actions.UpdateClient]: ALL_USER_ROLES,
  [Actions.DeleteClient]: ALL_USER_ROLES,

  [Actions.CreateContactPerson]: ALL_USER_ROLES,
  [Actions.GetContactPerson]: ALL_USER_ROLES,
  [Actions.UpdateContactPerson]: ALL_USER_ROLES,
  [Actions.DeleteContactPerson]: ALL_USER_ROLES,

  [Actions.CreateCompany]: [UserRoles.CompanyAdmin],
  [Actions.GetCompany]: ALL_USER_ROLES,
  [Actions.UpdateCompany]: [UserRoles.CompanyAdmin],

  [Actions.CreateOperator]: [UserRoles.CompanyAdmin],
  [Actions.GetOperator]: ALL_USER_ROLES,
  [Actions.UpdateOperator]: [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],

  [Actions.GetUser]: ALL_USER_ROLES,

  [Actions.ChangeUserStatus]: [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],
  [Actions.InviteUser]: [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],
  [Actions.ResendUserInvitation]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
  ],
  [Actions.UpdateUserRoles]: [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],
  [Actions.UpdateOtherUsers]: [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],

  [Actions.UpdateUserOperator]: [
    UserRoles.CompanyAdmin,
    UserRoles.OperatorAdmin,
  ],

  [Actions.GetRequest]: ALL_USER_ROLES,
  [Actions.UpdateRequest]: ALL_USER_ROLES,

  [Actions.GetOffer]: ALL_USER_ROLES,
  [Actions.UpdateOfferStatus]: ALL_USER_ROLES,
  [Actions.UpdateOffer]: ALL_USER_ROLES,
  [Actions.DeleteOffer]: ALL_USER_ROLES,
  [Actions.CancelOffer]: ALL_USER_ROLES,
  [Actions.RebookOffer]: ALL_USER_ROLES,
  [Actions.SendOfferByEmail]: ALL_USER_ROLES,

  [Actions.GetAirport]: ALL_USER_ROLES,

  [Actions.GetChat]: ALL_USER_ROLES,
  [Actions.SendChatMessage]: ALL_USER_ROLES,

  [Actions.GetSchedule]: ALL_USER_ROLES,
  [Actions.CreateOutage]: ALL_USER_ROLES,
  [Actions.UpdateOutage]: ALL_USER_ROLES,
  [Actions.DeleteOutage]: ALL_USER_ROLES,
  [Actions.UpdateMarketplaceExtension]: ALL_USER_ROLES,
  [Actions.DeleteMarketplaceExtension]: ALL_USER_ROLES,

  [Actions.CreateDocument]: ALL_USER_ROLES,
  [Actions.ReadDocument]: ALL_USER_ROLES,
}

export const USER_ROLE_WEIGHTS: Record<UserRoles, number> = {
  [UserRoles.Operator]: 1,
  [UserRoles.Manager]: 2,
  [UserRoles.OperatorAdmin]: 3,
  [UserRoles.CompanyAdmin]: 4,
}

export const USER_REGISTRATION_HASH_QUERY_PARAMETER = 'hash'

export const userRegistrationStepTransitions: Record<
  UserRegistrationTypes,
  Record<UserRegistrationSteps, UserRegistrationSteps[]>
> = {
  [UserRegistrationTypes.UserRegistration]: {
    [UserRegistrationSteps.Initial]: [UserRegistrationSteps.UserRegistration],

    [UserRegistrationSteps.UserRegistration]: [UserRegistrationSteps.Initial],

    [UserRegistrationSteps.OperatorRegistration]: [],

    [UserRegistrationSteps.CompanyRegistration]: [],
  },

  [UserRegistrationTypes.CompanyRegistration]: {
    [UserRegistrationSteps.Initial]: [UserRegistrationSteps.UserRegistration],

    [UserRegistrationSteps.UserRegistration]: [
      UserRegistrationSteps.Initial,
      UserRegistrationSteps.CompanyRegistration,
    ],

    [UserRegistrationSteps.CompanyRegistration]: [
      UserRegistrationSteps.UserRegistration,
      UserRegistrationSteps.OperatorRegistration,
    ],

    [UserRegistrationSteps.OperatorRegistration]: [
      UserRegistrationSteps.CompanyRegistration,
    ],
  },
}
