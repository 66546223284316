import { action } from 'typesafe-actions'

import { CompanySettingsActionTypes } from '@app/store/pages/settings/companySettings/companySettings.constants'
import { PatchCompanyDto } from '@app/utils/api/types'
import { CompanyDto } from '@shared/dto/company.dto'

export const getCompanyAction = (id: number) =>
  action(CompanySettingsActionTypes.GetCompany, { id })

export const getCompanySuccessAction = (company: CompanyDto) =>
  action(CompanySettingsActionTypes.GetCompanySuccess, { company })

export const getCompanyFailureAction = (error: unknown) =>
  action(CompanySettingsActionTypes.GetCompanyFailure, { error })

export const patchCompanyAction = (
  id: number,
  patchCompanyDto: PatchCompanyDto,
) => action(CompanySettingsActionTypes.PatchCompany, { id, patchCompanyDto })

export const patchCompanySuccessAction = (company: CompanyDto) =>
  action(CompanySettingsActionTypes.PatchCompanySuccess, { company })

export const patchCompanyFailureAction = (error: unknown) =>
  action(CompanySettingsActionTypes.PatchCompanyFailure, { error })
