import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { UserDetailDto } from '@shared/dto/user.dto'
import { MyTeamListActionTypes } from '@app/store/pages/myTeam/myTeamList/myTeamList.constants'
import { OrderBy } from '@shared/types'
import * as myTeamActions from '@app/store/pages/myTeam/myTeamList/myTeamList.actions'

import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  OrderDirection,
} from '@app/constants'

type MyTeamAction = ActionType<typeof myTeamActions>

export type MyTeamListStore = {
  data: UserDetailDto[] | null
  isLoading: boolean
  error: unknown
  userDetailData: UserDetailDto | null
  userDetailError: unknown
  isUserDetailLoading: boolean
  IsUserUpdateLoading: boolean
  userUpdateError: unknown
  orderBy: OrderBy<UserDetailDto>
  orderDirection: OrderDirection | null
}

const initialMyTeamListState: MyTeamListStore = {
  error: null,
  isLoading: false,
  data: null,
  userDetailData: null,
  isUserDetailLoading: false,
  userDetailError: null,
  IsUserUpdateLoading: false,
  userUpdateError: null,
  orderBy: DEFAULT_ORDER_BY,
  orderDirection: DEFAULT_ORDER_DIRECTION,
}

const myTeamListReducer = produce<MyTeamListStore, [MyTeamAction]>(
  (state, action) => {
    switch (action.type) {
      case MyTeamListActionTypes.GetMyTeamListData:
        state.isLoading = true
        state.data = null
        state.error = null

        break

      case MyTeamListActionTypes.GetMyTeamListSuccess:
        state.data = action.payload.data
        state.isLoading = false
        state.error = null

        break

      case MyTeamListActionTypes.GetMyTeamListFailure:
        state.isLoading = false
        state.error = action.payload

        break

      case MyTeamListActionTypes.GetUserData:
        state.isUserDetailLoading = true
        state.userDetailData = null
        state.userDetailError = null

        break

      case MyTeamListActionTypes.GetUserDataSuccess:
        state.userDetailData = action.payload
        state.isUserDetailLoading = false
        state.userDetailError = null

        break

      case MyTeamListActionTypes.GetUserDataFailure:
        state.isUserDetailLoading = false
        state.userDetailError = action.payload

        break

      case MyTeamListActionTypes.PatchUserData:
        state.IsUserUpdateLoading = true
        state.userUpdateError = null

        break

      case MyTeamListActionTypes.PatchUserDataSuccess:
        state.IsUserUpdateLoading = false
        state.userUpdateError = null

        break

      case MyTeamListActionTypes.PatchUserDataFailure:
        state.IsUserUpdateLoading = false
        state.userUpdateError = action.payload

        break

      case MyTeamListActionTypes.SetMyTeamListParameters:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection
        }

        break

      default:
        return state
    }
  },
  initialMyTeamListState,
)

export default myTeamListReducer
