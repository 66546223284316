import { RootState } from '@app/store'

export const selectMyTeamListOrderBy = (store: RootState) =>
  store.pages.myTeam.myTeamList.orderBy

export const selectMyTeamListData = (store: RootState) =>
  store.pages.myTeam.myTeamList.data

export const selectMyTeamListIsLoading = (store: RootState) =>
  store.pages.myTeam.myTeamList.isLoading

export const selectMyTeamListError = (store: RootState) =>
  store.pages.myTeam.myTeamList.error

export const selectMyTeamListOrderDirection = (store: RootState) =>
  store.pages.myTeam.myTeamList.orderDirection

export const selectUserDetailData = (store: RootState) =>
  store.pages.myTeam.myTeamList.userDetailData

export const selectIsUserDetailLoading = (store: RootState) =>
  store.pages.myTeam.myTeamList.isUserDetailLoading

export const selectUserDetailError = (store: RootState) =>
  store.pages.myTeam.myTeamList.userDetailError
