import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { BookingsReportsActionTypes } from '@app/store/pages/reports/bookingsReports/bookingsReports.constants'
import { BaseDataStore } from '@app/store/types'
import { BookingReportsDto } from '@shared/dto/reports.dto'
import { GetBookingsReportsFilters } from '@app/utils/api/types'

import * as bookingsReportsActions from '@app/store/pages/reports/bookingsReports/bookingsReports.actions'

type BookingsReportsAction = ActionType<typeof bookingsReportsActions>

export interface BookingsReportsStore extends BaseDataStore<BookingReportsDto> {
  filters: GetBookingsReportsFilters
}

const initialBookingsReportsState: BookingsReportsStore = {
  error: null,
  isLoading: false,
  data: null,
  filters: {},
}

const bookingsReportsReducer = produce<
  BookingsReportsStore,
  [BookingsReportsAction]
>((state, action) => {
  switch (action.type) {
    case BookingsReportsActionTypes.GetBookingsReports:
      state.isLoading = true
      state.error = null

      break

    case BookingsReportsActionTypes.GetBookingsReportsSuccess:
      state.isLoading = false
      state.data = action.payload
      state.error = false

      break

    case BookingsReportsActionTypes.GetBookingsReportsFailure:
      state.isLoading = false
      state.error = action.payload.error
      state.data = null

      break

    case BookingsReportsActionTypes.SetBookingsReportsFilters:
      if (action.payload.filters) {
        state.filters = { ...state.filters, ...action.payload.filters }
      }

      break

    case BookingsReportsActionTypes.ResetBookingsReportsFilters:
      state.filters = {}

      break

    default:
      return state
  }
}, initialBookingsReportsState)

export default bookingsReportsReducer
