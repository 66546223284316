import { action } from 'typesafe-actions'

import { ClientDetailActionTypes } from '@app/store/pages/clientDetail/clientDetail/clientDetail.constants'
import { ClientDetailDto } from '@shared/dto/clients.dto'
import { PostClientDto } from '@app/utils/api/types'

export const getClientDetailAction = (id: number) =>
  action(ClientDetailActionTypes.GetClientDetail, id)

export const getClientDetailSuccessAction = (response: ClientDetailDto) =>
  action(ClientDetailActionTypes.GetClientDetailSuccess, response)

export const getClientDetailFailureAction = (error: unknown) =>
  action(ClientDetailActionTypes.GetClientDetailFailure, error)

export const patchClientAction = (
  id: number,
  partialClientDto: Partial<PostClientDto>,
) => action(ClientDetailActionTypes.PatchClient, { id, partialClientDto })

export const patchClientSuccessAction = (client: ClientDetailDto) =>
  action(ClientDetailActionTypes.PatchClientSuccess, { client })

export const patchClientFailureAction = (error: unknown) =>
  action(ClientDetailActionTypes.PatchClientFailure, { error })
