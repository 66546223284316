// withTranslation

import { AxiosResponse } from 'axios'
import produce from 'immer'

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ClientsActionTypes } from '@app/store/pages/clients/clientsList/clientsList.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { GetClientsResponse } from '@app/utils/api/types'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'

import * as actions from '@app/store/pages/clients/clientsList/clientsList.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

import {
  selectClientsFilters,
  selectClientsLimit,
  selectClientsOrderBy,
  selectClientsOrderDirection,
  selectClientsPage,
} from '@app/store/pages/clients/clientsList/clientsList.selectors'

import * as DTOs from '@shared/dto'

function* getClientsDataSaga(page: number) {
  try {
    const limit = selectClientsLimit(yield select())
    const order_direction = selectClientsOrderDirection(yield select())
    const filters = selectClientsFilters(yield select())
    const order_by = selectClientsOrderBy(yield select())
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const params: DTOs.GetClientsQuery = {
      operator_id: Number(operator.id),
      ...filters,
      order_by: order_by ? order_by : undefined,
      order_direction: order_direction ? order_direction : undefined,
      limit,
      page,
    }

    const { data }: AxiosResponse<GetClientsResponse> = yield call(
      api.getClients,
      params,
    )

    yield put(actions.getClientsSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getClients.default')
      default: 'errors.getClients.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getClientsFailureAction(error))
  }
}

function* getInitialClientsDataSaga() {
  yield getClientsDataSaga(1)
}

function* getMoreClientsDataSaga() {
  const page = selectClientsPage(yield select())

  yield getClientsDataSaga(page + 1)
}

function* setClientsFiltersSaga(
  action: ReturnType<typeof actions.setClientsFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(ClientsActionTypes.SetClientsFilters),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getClientsDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getClientsFailureAction(error))
  }
}

function* setClientsSortSaga() {
  try {
    yield put(actions.getClientsDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getClientsFailureAction(error))
  }
}

function* reloadClientsSaga() {
  const limit = selectClientsLimit(yield select())
  const page = selectClientsPage(yield select())
  const order_direction = selectClientsOrderDirection(yield select())
  const filters = selectClientsFilters(yield select())
  const order_by = selectClientsOrderBy(yield select())
  const operator = selectSelectedOperator(yield select())

  if (!operator) {
    throw new Error('Operator is required')
  }

  const params: DTOs.GetClientsQuery = {
    operator_id: Number(operator.id),
    ...filters,
    order_by: order_by ? order_by : undefined,
    order_direction: order_direction ? order_direction : undefined,
    limit: limit * page,
    page: 1,
  }

  try {
    const { data }: AxiosResponse<GetClientsResponse> = yield call(
      api.getClients,
      produce(params, (draft) => {
        if (draft.limit && draft.page) {
          draft.limit = draft.limit * draft.page
        }
      }),
    )

    yield put(actions.reloadClientsSuccessAction(data))
  } catch (error) {
    console.error(error)

    yield put(actions.getClientsFailureAction(error))
  }
}

function* deleteClientSaga(
  action: ReturnType<typeof actions.deleteClientAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmations.deleteClientConfirmation')
        i18nextKey: 'confirmations.deleteClientConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      return
    }

    yield call(api.deleteClient, action.payload.id)

    yield put(
      addNotificationAction({
        // t('messages.deleteClient.success')
        i18nextKey: 'messages.deleteClient.success',
        type: 'success',
      }),
    )

    yield put(actions.deleteClientSuccessAction())
    yield put(dialogActions.closeConfirmationDialogAction())
    yield put(actions.reloadClientsAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteClient.notFound')
      404: 'errors.deleteClient.notFound',
      // t('errors.deleteClient.default')
      default: 'errors.deleteClient.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.deleteClientFailureAction(error))
  }
}

export default function* watchClientsSaga(): Generator {
  yield takeLatest(
    ClientsActionTypes.GetClientsDataFirstPage,
    getInitialClientsDataSaga,
  )

  yield takeLatest(
    ClientsActionTypes.GetClientsDataNextPage,
    getMoreClientsDataSaga,
  )

  yield takeLatest(ClientsActionTypes.ReloadClients, reloadClientsSaga)

  yield takeLatest(ClientsActionTypes.SetClientsFilters, setClientsFiltersSaga)

  yield takeLatest(ClientsActionTypes.SetClientsSort, setClientsSortSaga)

  yield takeLatest(ClientsActionTypes.DeleteClient, deleteClientSaga)
}
